import { requestPost } from './http';
export const convertMallIndex = params => requestPost('/api/convert-mall/index', params); //商城首页
export const IndexGoodsList = params => requestPost('/api/convert-mall/index-goods-list', params); //获取商城首页商品列表
export const jdHomeSearchCondition = params => requestPost('/api/convert-mall/jd-home-search-condition', params); //获取京东首页筛选条件
export const jdGoodsList = params => requestPost('/api/convert-mall/jd-goods-list', params); //获取京东商品列表
export const jdCategoryList = params => requestPost('/api/convert-mall/jd-category-list', params); //获取京东全品类分类列表
export const jdSearchPageCondition = params => requestPost('/api/convert-mall/jd-search-page-condition', params); //获取京东搜索页面筛选条件

export const selfGoodsDetail = params => requestPost('/api/convert-mall/self-goods-detail', params); //获取自营商品详情

export const selfGoodsList = params => requestPost('/api/convert-mall/self-goods-list', params); //获取自营商品列表
export const selfHomeSearchCondition = params => requestPost('/api/convert-mall/self-home-search-condition', params); //获取自营首页筛选条件
export const selfSearchPageCondition = params => requestPost('/api/convert-mall/self-search-page-condition', params); //获取自营搜索页面的筛选条件
export const jdGoodsDetail = params => requestPost('/api/convert-mall/jd-goods-detail', params); //获取京东商品详情
export const jdGoodsSaleStatusByArea = params => requestPost('/api/convert-mall/jd-goods-sale-status-by-area', params); //获取京东商品指定地区的库存是否充足，并且可以售卖
export const getAreaList = params => requestPost('/api/convert-mall-area/get-area-list', params); //获取行政区划列表
export const getDefaultAddress = params => requestPost('/api/convert-mall-user-address/get-default-address', params); //获取用户默认收货地址详情
export const jdGoodsFreight = params => requestPost('/api/convert-mall/jd-goods-freight', params); //获取京东商品指定地区的运费信息

export const getAddressList = params => requestPost('/api/convert-mall-user-address/get-list', params); //获取用户收货列表
export const getAddressDetail = params => requestPost('/api/convert-mall-user-address/get-detail', params); //获取用户收货地址详情
export const doAddressDelete = params => requestPost('/api/convert-mall-user-address/do-delete', params); //删除用户收货地址
export const doAddressSave = params => requestPost('/api/convert-mall-user-address/do-save', params); //新增，编辑用户收货地址

export const orderCommit = params => requestPost('/api/convert-mall-order/order-commit', params); //提交订单接口
export const orderDetail = params => requestPost('/api/convert-mall-order/order-detail', params); //获取订单详情
export const orderPay = params => requestPost('/api/convert-mall-order/order-pay', params); //订单支付

export const orderList = params => requestPost('/api/convert-mall-order/order-list', params); //获取订单列表
export const cancelOrder = params => requestPost('/api/convert-mall-order/order-cancel', params); //取消订单
export const checkAddressValid = params => requestPost('/api/convert-mall-user-address/check-address-valid', params); //验证地址是否有效
export const getJdDeliverInfo = params => requestPost('/api/convert-mall-order/get-jd-deliver-info', params); //获取京东订单物流信息